
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  @Component({
    name:"breadCrumb4"
  })
  export default class breadCrumb4 extends Vue {
    @Prop(Array) breadCrumbList_?: any
    @Prop(Number) divWidth_?: any
    @Prop(Number) divHeight_?: any
    mounted(){
    }
  }
