
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  import goodsDeatil from "~/utils/goodsDetail.js"
  import {getNewsInfoHttp} from  "~/api/comm.js"
import newImg from '../newImg/index.vue';
  @Component({
    name:"detailGoodsDetail2",
    components: {
      newImg: require('~/components/comm/newImg/index.vue').default,
      detailAccountScreenshot: require('~/components/comm/goods/detailAccountScreenshot.vue').default,
    }
  })
  export default class detailGoodsDetail2 extends Vue {
    @Prop(Object) goodsInfo_?: any
    public changeToMobileUrl: any;//调用公告方法plugins/mixin.js
    public copy:any
    private QRCode:any
    public hidden:number=1
    private a:boolean=false
    public isShowMobileFloat:boolean=false
    private showMoreText:string= '更多'
    private detail:any= {}
    private detail2:any= {}
    private whichPlatform:string= ''
    public purchaseProcess:any = ''
    public purchaseTips:any = ''
    private errorToastFn:any;
    public showGoodsDesc:boolean = false;
    public showGoodsInfo:boolean = false;
    public isGoodsInfoMoreBtn:boolean = true;

    mounted() {

      this.whichPlatform = window.location.host
      // 详情处理
      this.detail = goodsDeatil
      console.log(this.goodsInfo_,'tesza')
      try {
        var data = this.detail[String(this.goodsInfo_.gameId)]
        this.detail2 = {}
        if (!!data && data[this.goodsInfo_.goodsTypeId]) {
          this.detail2 = {...data[this.goodsInfo_.goodsTypeId]}
        } else {
          this.detail2 = {...data[1]}
        }
      } catch (e) {
        this.detail2 = {}
      }
      this.getPurchaseProcess()
      this.getPurchaseTips()
      let getTitleList = this.getTitleList;
      console.log(this.getTitleList,"你好啊~1231312！6！")
      this.isGoodsInfoMoreBtn = getTitleList[0].length > 0 ? Math.ceil(getTitleList[0].length/3) + getTitleList[1].length <= 10 ? false : true : getTitleList[1].length <= 10 ? false : true ;

    }
    get getTitleList() {
      console.log(this.goodsInfo_,"你好啊~1231312！！")
      if(this.goodsInfo_.goodsShowTileList) {
        let flag:any = this.goodsInfo_.goodsShowTileList.filter((v:any) => {
          return v.type == 'noDetail'
        })
        let data:any = [
          [],[],[]
        ]
        this.goodsInfo_.goodsShowTileList.map((v:any) => {
          // console.log('你好啊',v.key.length,v.key.substring(v.key.length-6))
          if(v.type == 'noDetail'&&(v.key.substring(v.key.length-6)==='Number' || v.key.substring(v.key.length-4)==='Name')){
            data[0].push(v)
          } else if (v.type == 'noDetail' && v.key.substring(v.key.length-5) === 'Names' && this.getGoodsDetailValue(v.key) !== '无'){
            data[1].push(v)
          } else if (v.type == 'noDetail') {
            data[2].push(v)
          }
        })
        console.log("你好啊！！！！！",data);

        return data
      } else {
        return []
      }
    }
    @Emit()
    show(v:number) {
      this.hidden = v
    }
    @Emit()
    showMore() {
      this.a=!this.a
      if (!this.a) {
        this.showMoreText='更多'
      } else {
        this.showMoreText='收起'
      }
    }
    @Emit()
    //生成二维码
    showMobileErWeiMa(flag:boolean) {
      this.isShowMobileFloat=flag
      if (flag==true) {
        // let url = this.changeToMobileUrl({
        //   type: 'goods',
        //   goodsTypeId: this.goodsInfo_.goodsTypeId,
        //   sn: this.$route.params.sn,
        //   gameId: this.goodsInfo_.gameId
        // })
        let url = window.location.origin + '/detail/' + this.$route.params.sn
        let msgDom =null
        msgDom = document.getElementById('canvasQR')
        console.log('routerouterouteroute',url,msgDom)
        if(!!msgDom){
          this.QRCode.toCanvas(msgDom, url, async (error:any) => {
            console.log('"------二维码----error-----"',error)
          })
        }
      }
    }
    @Emit()
    getGoodsDetail(){
      if (this.goodsInfo_.goodsShowTileList) {
        // console.log(this.goodsInfo_.goodsShowTileList.filter((v:any) => v.type=='noDetail'),'===goodsShowTileList==哈哈哈1=')
        let flag:any=this.goodsInfo_.goodsShowTileList.filter((v:any) => v.type=='noDetail')
        return flag
      } else {
        return []
      }
    }
    @Emit()
    getGoodsDetailValue(key:string){
      let flagArray:any=key.split(',')
      let flagValue:string=''
      for (let i:number=0;i<flagArray.length;i++){
        if (this.goodsInfo_ && this.goodsInfo_[flagArray[i]]){
          if (flagValue) {
            flagValue=`${flagValue}-${this.goodsInfo_[flagArray[i]]}`
          } else {
            flagValue=this.goodsInfo_[flagArray[i]]
          }
        }
      }
      if (flagValue) {
        return flagValue
      } else {
        return '无'
      }
    }
    // 点击进群
    @Emit()
    joinToGroup(encryptNumber:any) {
      window.open(encryptNumber,'_blank')
    }
    @Emit()
    async getPurchaseProcess() {
      try {
        const {data} = await getNewsInfoHttp({params:{newsId:this.goodsInfo_.gameId}})
        if(this.errorToastFn(data)) {
          this.purchaseProcess = data.data.content
        } else {
          this.purchaseProcess = ''
        }
      } catch (error) {
        this.purchaseProcess = ''
      }
    }
    @Emit()
    async getPurchaseTips() {
      try {
        const {data} = await getNewsInfoHttp({params:{newsId:108}})

        if(this.errorToastFn(data)) {
          this.purchaseTips = data.data.content

        } else {
          this.purchaseTips = ''
        }
      } catch (error) {
        this.purchaseTips = ''
      }
    }

    showGoodsInfoTitle(index:any,index2:any){
      let data = this.getTitleList;
      console.log(this.getTitleList,"xiaolongdemo666")
      if (this.showGoodsInfo){
        return true
      } else {
        return index === 0 ? true : data[0].length > 0 ? Math.ceil(data[0].length/3) + index2 + 1 <= 10 : 0 + index2 + 1 <= 10;
      }
    }

    moreGoodsInfo(v:any){
      if(v === 1){
        this.showGoodsDesc = !this.showGoodsDesc
      } else if(v === 0) {
        this.showGoodsInfo = !this.showGoodsInfo
      }
    }
  }
