
import {Component, Vue, Emit, Prop} from 'vue-property-decorator'
import {State, Getter, Action, Mutation, namespace} from 'vuex-class'
import {getSeckillList, goodsListHttp} from "~/api/goods";

@Component({
  name: "timer",
  components: {},
  asyncData() {
  }
})
export default class timer extends Vue {
  @Prop({default: () => []}) Timer?: any;
  // public Timer:any="2023-7-19 14:00:00";
  public d: any = '';
  public h: any = '';
  public m: any = '';
  public s: any = '';
  public sum_h: any = '';
  public leftTime: any = '';

  mounted() {
    this.countTime(this.Timer)
  }
  countTime(time: any) {
    // 获取当前时间
    var date = new Date();
    var now = date.getTime();
    //设置截止时间
    var endDate = new Date(time);
    var end = endDate.getTime();
    //时间差
    this.leftTime = end - now;
    // console.log(this.leftTime)
    //定义变量 d,h,m,s保存倒计时的时间
    if (this.leftTime) {
      this.d = Math.floor(this.leftTime / 1000 / 60 / 60 / 24);
      this.h = Math.floor(this.leftTime / 1000 / 60 / 60 % 24);
      this.m = Math.floor(this.leftTime / 1000 / 60 % 60);
      this.s = Math.floor(this.leftTime / 1000 % 60);
      this.sum_h = this.d * 24 + this.h
    }
    this.$emit('timeWatch',this.leftTime,this.sum_h)
    // console.log(this.s);
    //递归每秒调用countTime方法，显示动态时间效果
    setTimeout(() => this.countTime(this.Timer), 100);
  }

  @Emit()
  start() {
    /*    this.timer = setInterval(function () {
          this.time = this.countDown();
        }, 1000)*/
  }

  @Emit()
  destroyed() {
    // clearInterval(this.timer); // 组件销毁时清除定时器
  }
}
