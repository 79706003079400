
    import { Component, Vue, Emit, Prop, Watch } from "vue-property-decorator";

    export default class feestandards extends Vue {
       public feedialog:any=false;


        mounted() {
            
        }

      
        
    }
