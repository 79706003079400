
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// @ts-ignore
import serviceSafe from '~/config/goods/serviceSafe.js'
import {
  followGoodsHttp,
  getUserInviteCodeHttp,
  getAttentionHttp, getUserInfoHttp
} from "~/api/user";
import { bargainingListHttp, checkAttentionStatus, createSeckillOrder, Bargainingdeposit, Bargainingorderfor, Bargainingrecord, QueryKAJgoods } from '~/api/goods'
import Base64 from '~/plugins/base64'
import axios from "axios";
import { wechatLoginHttp } from "~/api/operation";
import { allocationCustomers } from "~/api/chat"
import { createInviteCodeRelate, ipinfo } from "~/api/comm";
import {payshow} from "~/api/pay";
import log from '../center/extend/log.vue';

@Component({
  name: "detailGoodsInfo3",
  components: {
    gameAccountDetail: require('~/components/comm/goods/gameAccountDetail.vue').default,
    dcModal6: require('~/components/comm/htmlLabel/dcModal6.vue').default,
    newImg: require('~/components/comm/newImg/index.vue').default,
    imgPreviewViewer: require('~/components/comm/imgPreviewViewer/index.vue').default,
    timer: require('~/components/comm/goodsList/timer.vue').default,
    feestandards: require('~/components/comm/goods/feestandards.vue').default,
    charging:require('~/components/comm/goods/goodsList/chargingstandard.vue').default,
  },
})
export default class detailGoodsInfo3 extends Vue {
  @State(state => state.web.distributorsObject) distributorsObject!: any;
  // @State(state => state.web.distributorsObject) distributorsObject!: any;
  @Prop({
    default: () => {
    }
  }) goodsInfo_?: any
  @Prop({
    default: () => {
    }
  }) preSaleSerObj_?: any
  

  @Prop(Number) ensureRatio_?: any
  @Prop(Number) ensureMinPirce_?: any
  public getCookie: any
  public checkDialogVisible: boolean = false;
  public confirmDialogVisible: boolean = false
  public setCookie: any
  public openUrl: any
  public eventMsgTost: any
  public eventNotifyTost: any
  public openToService: any;
  public errorToastFn: any
  public changePrice: any
  public copy: any
  public openQQLink: any
  public leftTime: number = -1
  public openOnlineService: any
  public isMyGoods: any
  public Timer: any = ''
  public Bargaining: boolean = false //砍价按钮是否显示   默认不显示砍价
  public kan_mask: boolean = false  //遮罩层
  public kan_input: any = '' //输入框
  public kan_checked: boolean = false //接受条件
  private attention: any = '';
  public endTime: any = '';
  public endTimes: any = ''; //倒计时
  private baozhang: number = 0//选中的保障服务下标
  private isFav: boolean = false//该商品是否已收藏
  private favIndex: string = ''//该商品在收藏表的id，用于删除
  private serviceSafeList: any = [] //保障服务列表
  private serviceSafeList2: any = [] //保障服务列表
  private secondTitle: String = '' //二级标题
  private nowServiceIndex: any = 0
  private dialogVisible: boolean = false//口令提示框
  private specialButton: any = {
    name: '',
    url: ''
  } //特殊按钮（分期购按钮）
  private specialHelpButton: any = {
    name: '',
    url: ''
  } //特殊按钮帮助中心（分期购帮助）
  private bargainShow: boolean = false
  private tap: number = 0
  public finalPrice: any = ''        // 最终价格
  private host: any = ''
  private msg: any = ''
  private showDetailItemIndex: any = -1
  private showBox: boolean = false
  private thumbnailArea: any = []
  private isService: boolean = false;
  private goodsTitleHeight: any = 0;
  private goodsTitleIsShow: any = false;
  private isShowCheckCustomer: any = true;
  public param1: any = {
    userId: '',
    goodSn: '',
    version: '1.0.31'
  };
  public timer1: any = null;
  private extra: any;
  private visitorProduct: any = {
    visitorProduct:
    {
      title: "",
      price: "",
      img: "",
      url: "",
    }
  }
  public inviteCode: any = '';
  public goods_sn: any = ''
  public timeGood: number = 0;
  public Bargain: any = ''//砍价定金
  public yeuserId = ''//当前登录id

  public isBuy: any = true;
  public kanjinfor: any = {}
  public showKanjiaModel: boolean = false;
  public showKanjiaBtn: any = "";
  public feedialog: boolean = false;
  public isBargaining:any = null;
  public filterList:any = []

  public tabOptions = [
      {
        value:1,
        name:'挂牌代售'
      },
    
      {
        value:2,
        name:'包赔中介'
      },
      {
        value:3,
        name:'普通中介'
      },
    ]

    public selected:number = 1
    public showCharging:boolean = false



  created(){
    
  }

  mounted() {
    this.yeuserId = this.getCookie('userId')
    console.log(this.yeuserId, '++++++++++++++++');

   
    // this.kantime()
    this.getgoods()
    this.getiemr()
    this.payshow()

    console.log(this.kanjinfor, "66966")

    if (this.$route.query.recommend_code) {
      this.getCode();
    }

    console.log(this.goodsInfo_, '你好啊~1231312！！');

    this.Timer = this.goodsInfo_.seckillTime;
    this.goods_sn = this.goodsInfo_.goodsSn;
    this.timeGood = Date.now() - Date.parse(this.goodsInfo_.attention_time);
    this.host = window.location.host
    //分享保存cookic
    this.inviteCode = new URL(window.location.href).searchParams.get('inviteCode')
    if (this.inviteCode != null) {
      this.setCookie("inviteCode", this.inviteCode)
      this.setCookie("goods_sn", this.goods_sn)
    }
    console.log("goods_sn", this.getCookie('inviteCode'))
    console.log("goods_sn", this.getCookie('goods_sn'))
    // this.dealPrice()
    console.log(this.$store.state.web.distributorsObject, this.getCookie('token'), '===distributorsObject==')
    // this.goodsInfo_.price=this.changePrice(this.goodsInfo_.price)
    //是否收藏赋值
    this.isFav = this.goodsInfo_.isFollow
    //获取二级标题
    this.getSecondTitle(this.goodsInfo_)

    //获取保障服务列表
    this.getServiceSafe();
    this.getheight();
    this.timer1 = setTimeout(() => {
      if (this.$store.state.web.distributorsObject.distBaseConf.inPurContLink) {
        try {
          if (typeof JSON.parse(this.$store.state.web.distributorsObject.distBaseConf.inPurContLink) == "object") {
            this.specialButton = JSON.parse(this.$store.state.web.distributorsObject.distBaseConf.inPurContLink); //可以将json字符串转换成json对象
          } else {
            this.specialButton.name = '分期购买'
            this.specialButton.url = this.$store.state.web.distributorsObject.distBaseConf.inPurContLink
          }
        } catch (e) {
          this.specialButton.name = '分期购买'
          this.specialButton.url = this.$store.state.web.distributorsObject.distBaseConf.inPurContLink
        }
        this.specialButton = { ...this.specialButton }
      }
      if (this.$store.state.web.distributorsObject.distBaseConf.inPurContHtUrl) {
        try {
          if (typeof JSON.parse(this.$store.state.web.distributorsObject.distBaseConf.inPurContHtUrl) == "object") {
            this.specialHelpButton = JSON.parse(this.$store.state.web.distributorsObject.distBaseConf.inPurContHtUrl); //可以将json字符串转换成json对象
          } else {
            this.specialHelpButton.name = '分期购帮助说明'
            this.specialHelpButton.url = this.$store.state.web.distributorsObject.distBaseConf.inPurContHtUrl
          }
        } catch (e) {
          this.specialHelpButton.name = '分期购帮助说明'
          this.specialHelpButton.url = this.$store.state.web.distributorsObject.distBaseConf.inPurContHtUrl
        }
        this.specialHelpButton = { ...this.specialHelpButton }
      }
    }, 50)
  }

    handleClose(){
      this.showCharging = false
    }

    async getiemr() {
     let tiemr = new Date(this.goodsInfo_.attention_time).getTime()+ 24 * 3600 * 1000;
     this.endTimes = tiemr
    //  console.log(this.endTimes,'mei');

    }

    @Emit()
  async payshow(){ //判断是否开启砍价
    let res = await payshow({})

    if(res?.data.code == 0){
      this.isBargaining = res?.data.data.list[2].Open
    }

  }

  



  async getCode() {
    console.log(this.host, "1231qqqqq2312");
    if (!this.getCookie('ip_code')) {
      let IP: any = await ipinfo();
      if (this.$route.query.recommend_code) {
        this.inviteCode = this.$route.query.recommend_code;
        let data = await createInviteCodeRelate({
          method: "POST",
          data: {
            recommend_code: this.inviteCode
          },
        });
        this.setCookie('ip_code', IP?.data.data + '|' + this.inviteCode);
        console.log(data, this.inviteCode, "1231qqqqq2312");
      }
    }
  }


  @Emit()
  async conFirmBargin() {
    let res = await Bargainingorderfor({
      method: 'POST', data: {
        phone: this.getCookie('phoneNum'),//手机号
        goodSn: this.goodsInfo_.goodsSn,//商品编号
        depositPrice: this.Bargain,//定金
        price: this.kan_input,//报价
        gameId: this.goodsInfo_.gameId,//游戏id
      }
    })
    if (res?.data.code == 1) {//判断商品是否存在

      return this.$message.warning(`该商品砍价已存在，请前往-个人中心-买家中心-我的砍价订单支付定金或直接跳转至付款页面`)
    } else {
      await Bargainingrecord({
        method: 'POST', data: {
          userType: 1,//用户类型
          goodsCode: this.goodsInfo_.goodsCode,//局域网编号
          goodsId: this.goodsInfo_.id,//商品id
          price: this.kan_input,//报价
          orderSn: res?.data.data?.order_sn,//商品编号
        }
      })
    }
    let datas = {
      goodsSn: this.goodsInfo_.goodsSn,
      orderSn: res?.data.data.order_sn,
    }
    setTimeout(() => {
      this.$router.push({
        path: '/Bargbuy/Babuy',
        query: datas,
      })
    }, 500);
    this.confirmDialogVisible = false;

  }

  @Emit()
  async sureCheck() {
    this.checkDialogVisible = false;
    this.openUrl('/center/buyers/BuyBargain')
  }
  @Emit()
  kantime() {
    if (this.goodsInfo_.bargainTime + 3600 > Math.round(new Date().getTime() / 1000)) {
      this.isBuy = true
    } else {
      this.isBuy = false
    }

  }



  chengdialog() {

    this.showCharging = true //操作列表是否显示
    
    console.log('++++++++++++');
    
    
  }

  //跳转客服
  @Emit()
  async goService(goodsInfo: any) {
    console.log(goodsInfo.goodsSn,"获取到的当前的商品详情")

    // console.log(window.location.host,2222222123123);

    let userId = this.getCookie('userId');

    if (userId) {
      // let userData:any = await getUserInfoHttp()
      let phoneNum = this.getCookie('phoneNum')
      this.visitorProduct.visitorProduct.title = goodsInfo.bigTitle;
      this.visitorProduct.visitorProduct.goodsSn = goodsInfo.goodsSn;
      this.visitorProduct.visitorProduct.price = "￥" + goodsInfo.price;
      this.visitorProduct.visitorProduct.img = "https://oss1.hk1686.com/" + goodsInfo.goodsImages[0].url;
      console.log("获取到的商品路径",goodsInfo.goodsImages[0].url)
      this.visitorProduct.visitorProduct.url = window.location.href;
      goodsInfo.price = (Number(goodsInfo.price).toFixed(0)).toString();
      this.extra = JSON.stringify(this.visitorProduct);
      let base = new Base64();
      this.extra = base.encode(this.extra);

      await allocationCustomers({
        userId: userId,
        price: goodsInfo.price,
        gameId: goodsInfo.gameId,
        customerType: 1,
        goodSn: goodsInfo.goodsSn,
        goodCode: goodsInfo.goodsCode
      }).then((res: any) => {
        if (res.result.customerId && res.result.customerId !== 0) {
          console.log(res.result.customerId,this.extra,"我获取到的当前客服信息")
          this.openToService(res.result.customerId, this.extra,"",goodsInfo.goodsSn)
        } else {
          this.$message.warning('当前所有客服处于繁忙状态，请稍后再试！敬请谅解！');
        }
      });
      // console.log(data,7777777)
    } else {
      await this.$router.push('/login');
    }
  }

  get loginWay() {
    return (str: string) => {
      if (str === 'title') {
        return this.goodsInfo_.loginWayName || this.goodsInfo_.loginTypeName || this.goodsInfo_.accounttypesixName ? '账号类型' : '商品类型'
      } else {
        return this.goodsInfo_.loginWayName ? this.goodsInfo_.loginWayName : this.goodsInfo_.loginTypeName ? this.goodsInfo_.loginTypeName : this.goodsInfo_.accounttypesixName ? this.goodsInfo_.accounttypesixName : this.goodsInfo_.goodsTypeName
      }
    }
  }

  getLeft(leftTime: number) {
    this.leftTime = leftTime;
  }

  //处理价格
  async dealPrice() {
    let data1: any = []
    if (!!window.localStorage.bargainList) {
      data1 = JSON.parse(window.localStorage.bargainList)
      console.log(data1[0], window.localStorage.bargainList, JSON.parse(window.localStorage.bargainList), 'sdnklm');
      let flag: any = data1.findIndex((v: any) => v.goodsSn == this.goodsInfo_.goodsSn)
      console.log(flag, this.goodsInfo_.goodsSn, 'sdnklm')
      if (flag > -1) {
        data1.splice(flag, 1)
      }
    }
    if (!this.getCookie()) {
      console.log(this.goodsInfo_.goodsTypeId, 'goodsTypeId1');

      if (this.goodsInfo_.goodsTypeId == 2 || this.goodsInfo_.goodsTypeId == 22) {
        this.finalPrice = this.goodsInfo_.price
      } else {
        this.finalPrice = this.changePrice(this.goodsInfo_.price, this.goodsInfo_.addPrice)
        // console.log(this.goodsInfo_.goodsTypeId,'goodsTypeId2');
        // console.log(this.finalPrice,'this.finalPrice');

      }
      window.localStorage.setItem('bargainList', JSON.stringify(data1));
      return
    }
    let myAgreeBarginList: any = []
    try {
      const { data } = await bargainingListHttp({
        params: {
          typeIndex: 0,
          pages: 1,
          pageSize: 10000,
          keyword: `goodsSn=${this.goodsInfo_.goodsSn}`
        }
      })
      console.log(data, 'ecnklnkl');
      if (data && data.code == 0 && data.data.count > 0) {
        myAgreeBarginList = data.data.data.filter((v: any) => v.status == 1)
      } else {
        myAgreeBarginList = []
      }
    } catch (error) {
      myAgreeBarginList = []
      console.log(error, '请求错误');
    }
    if (myAgreeBarginList.length > 0) {
      console.log(myAgreeBarginList[0], 'sdnklm');
      data1.push(myAgreeBarginList[0])
      window.localStorage.setItem('bargainList', JSON.stringify(data1));
      if (this.goodsInfo_.goodsTypeId == 2 || this.goodsInfo_.goodsTypeId == 22) {
        this.finalPrice = this.goodsInfo_.price
      } else {
        this.finalPrice = this.changePrice(this.goodsInfo_.price, this.goodsInfo_.addPrice, this.goodsInfo_.goodsSn)
      }
    } else {
      console.log(this.goodsInfo_.price, 11112);
      window.localStorage.setItem('bargainList', JSON.stringify(data1));
      if (this.goodsInfo_.goodsTypeId == 2 || this.goodsInfo_.goodsTypeId == 22) {
        this.finalPrice = this.goodsInfo_.price
      } else {
        this.finalPrice = this.changePrice(this.goodsInfo_.price, this.goodsInfo_.addPrice)
      }
    }
  }

  get getTitleList() {
    console.log('ecjkljkljl', this.goodsInfo_.goodsShowTileList, 'ecjkljkljl');
    if (this.goodsInfo_.goodsShowTileList) {
      let flag: any = this.goodsInfo_.goodsShowTileList.filter((v: any) => {
        return v.type == 'default'
      })
      console.log(flag, 'ecjkljkljl');
      return flag
    } else {
      return []
    }
  }

  get serviceDecText() {
    if (this.serviceSafeList2.length > 0) {
      return this.serviceSafeList2[this.nowServiceIndex].tip == '购买该商品后签署买卖合同，充分保障买家安全' ? '购买的所有账号通过官方平台过户，百分百安全' : this.serviceSafeList2[this.nowServiceIndex].tip == '享受专属客服全程跟进，贴心服务' ? '猫猫审核评估 性价比极高' : this.serviceSafeList2[this.nowServiceIndex].tip;
    }
    return ''
  }

  getGoodsDetailValue(key: string) {
    let flagArray: any = key.split(',')
    let flagValue: string = ''
    if (flagArray.length > 0 && flagArray[0] !== '') {
      for (let i: number = 0; i < flagArray.length; i++) {
        if (this.goodsInfo_ && this.goodsInfo_[flagArray[i]]) {
          if (flagValue) {
            flagValue = `${flagValue}-${this.goodsInfo_[flagArray[i]]}`
          } else {
            flagValue = String(this.goodsInfo_[flagArray[i]])
          }
        }
      }
      return flagValue.replace(/,/g, ' , ')
    } else {
      return ''
    }
  }

  @Emit()
  async createSeckill(goodsSn: any) {
    this.param1.userId = this.getCookie('userId');
    this.param1.goodSn = goodsSn;
    let data: any = await createSeckillOrder({ method: 'POST', data: this.param1 });
    // console.log(data);
    this.msg = data.data.msg;

    /*  await this.$confirm(this.msg, '提示', {
        distinguishCancelAndClose: true, //区分取消和关闭
        confirmButtonText: '确定',
        showCancelButton:false,
        type: 'success'
      })*/
    /*if(this.msg==='成功')
    {
      this.tap = 1;
    }*/
    if (data.data.code === 0) {
      this.eventMsgTost('您已成功抢购！稍后客服会联系您！', 'success');
      setTimeout(() => {
        this.$router.go(0);
      }, 5000)
    } else {
      this.eventMsgTost(this.msg, 'warning');
    }
  }

  @Emit()
  baozhang1(v: number) {
    this.nowServiceIndex = v
  }

  @Emit()
  puynotice() {
    this.$router.push({
      path: '/news/3'
    })
  }

  @Emit()
  //获取保障服务列表
  async getServiceSafe() {
    this.serviceSafeList = serviceSafe
    console.log(this.serviceSafeList, 'poqrkjd');

    if (serviceSafe && this.goodsInfo_.goodsTypeId && this.serviceSafeList[this.goodsInfo_.goodsTypeId]) {
      this.serviceSafeList = this.serviceSafeList[this.goodsInfo_.goodsTypeId]
      await this.serviceSafeList.map((res: any) => {
        if (this.goodsInfo_ && this.goodsInfo_.securitService && String(this.goodsInfo_.securitService).indexOf(res.id) > -1) {
          if (this.goodsInfo_.securitService > 9 && res.id == 1) {
            return res
          }
          this.serviceSafeList2.push(res)
        }
      })
    } else if (serviceSafe && this.goodsInfo_.goodsTypeId && !this.serviceSafeList[this.goodsInfo_.goodsTypeId]) {
      this.serviceSafeList = serviceSafe[1]
      await this.serviceSafeList.map((res: any) => {
        if (this.goodsInfo_ && this.goodsInfo_.securitService && String(this.goodsInfo_.securitService).indexOf(res.id) > -1) {
          this.serviceSafeList2.push(res)
        }
      })
    }
    return this.serviceSafeList2
  }

  @Emit()
  //获取商品二级标题
  getSecondTitle(item: any) {
    let secondTitle: string = ''
    console.log(this.goodsInfo_.goodsShowTileList, '======goodsShowTileList==========')
    if (this.goodsInfo_.goodsShowTileList) {
      let flag: any = this.goodsInfo_.goodsShowTileList.find((v: any) => v.title == '二级标题')
      if (flag) {
        let flagArray: any = String(flag.key).split(',') //把字符串转数组
        for (let i: number = 0; i < flagArray.length; i++) {
          if (item[flagArray[i]]) {
            if (secondTitle) {
              secondTitle = `${secondTitle},${item[flagArray[i]]}`
            } else {
              secondTitle = item[flagArray[i]]
            }
          }
        }
        // console.log(secondTitle,'=======secondTitle========')
        this.secondTitle = secondTitle
      } else {
        this.secondTitle = secondTitle
      }
    } else {
      this.secondTitle = secondTitle
    }
  }

  @Emit()
  async addFav(v: number) {//收藏/取消收藏
    console.log(v, 'adsrghjkalj');

    if (this.goodsInfo_.goodsNum <= 0) {
      this.eventMsgTost("商品已出售", "warning",)
      return
    }
    if (!this.getCookie()) {
      this.eventMsgTost("请先登录，以享受更全面的服务体验", "warning",)
      return
    }
    try {
      let { data } = await followGoodsHttp({ method: 'post', data: { 'goodsId': v } })
      if (this.errorToastFn(data)) {
        if (data.data === 1) { //取消收藏
          this.eventMsgTost("取消收藏成功", "success")
          this.isFav = false
        } else {
          this.isFav = true
          this.eventMsgTost("收藏成功", "success")
        }
      }
    } catch (error) {
      this.eventNotifyTost('提示', '网络超时', 'warning')
    }
  }

  @Emit()
  async getgoods() { //支付状态

    let token = this.getCookie('token')

    if (token) {
      let res = await QueryKAJgoods({
        method: 'post', data: {
          goodsSn: this.goodsInfo_.goodsSn,
          userid: this.yeuserId
        }
      })
      this.kanjinfor = res?.data.data.data;
      console.log(this.kanjinfor, "xiaolong123");
      if (this.kanjinfor.Status === 2) {
        let endTime = new Date(this.kanjinfor.UpdateTime).getTime() + 24 * 3600 * 1000;
        this.endTime = endTime;
        console.log(this.endTime, "xiaolong626")

      }

      if (this.kanjinfor) {
        this.showKanjiaBtn = this.kanjinfor.Status;
        if ((this.showKanjiaBtn == 0 || this.showKanjiaBtn == 1) && this.goodsInfo_.goodsNum == 1 && this.goodsInfo_.showSign == 0) {
          this.showKanjiaModel = true
        } else {
          console.log("66677")
          console.log(this.showKanjiaBtn, "66677")
          console.log(this.showKanjiaBtn, "66677")
          console.log(this.goodsInfo_.goodsNum, "66677")
          console.log(this.goodsInfo_.showSign, "66677")
          console.log("777")
          this.showKanjiaModel = false
        }
        // this.showKanjiaModel = this.kanjinfor.Status;

      }


      console.log(this.kanjinfor, '++++++++++++++++++++++++++++1')
      localStorage.setItem('kanjinfor', this.kanjinfor.OrderSn)
    } else {

    }

  }

  @Emit()
  async buyGoods(url: string, v: number) {
   
    let token = this.getCookie('token');
    if (token.length < 1 || token == '') {
      this.openUrl('/login');
      return
    }
    // return
    if (this.goodsInfo_.attention === '*' && this.timeGood > 0 && this.timeGood < 3600000) {
      this.dialogVisible = true
      return
    }

    let { ...res }: any = await checkAttentionStatus({ params: { goodsId: v } });
    if (res.data.code === 1) {
      this.$message.error(res.data.msg);
      // window.location.reload();
      return;
    }

    if (this.goodsInfo_.goodsNum > 0 && this.goodsInfo_.showSign == 0) {
      this.openUrl(url, '', true)
    }
  }

  @Emit()
  async buyGoodsPwd(url: string) {
    if (this.goodsInfo_.attention === '*' && this.timeGood < 3600000 && this.timeGood > 0) {
      if (this.attention === '') {
        this.$message.error("不能为空!")
        return
      }
      let { data }: any = await getAttentionHttp({ params: { goodsId: this.goodsInfo_.id, attention: this.attention } })
      if (data.data.data === this.goodsInfo_.goodsSn) {
        this.dialogVisible = false
        if (this.goodsInfo_.goodsNum > 0 && this.goodsInfo_.showSign == 0) {
          this.openUrl(url + '?attention=' + this.attention, '', true)
        }
      } else {
        this.$message.error("口令错误，请重新输入!")
        this.dialogVisible = true
      }
    } else {
      this.$message.error("口令已超时!")
      this.dialogVisible = true
    }
  }

  @Emit()
  async copyUrl() {
    if (!this.getCookie()) {
      this.copy(window.location.href)
    } else {
      let { ...userData }: any = await getUserInfoHttp()
      if (userData.data.code == 0) {
        this.inviteCode = userData.data.data.recommendCode;
      }
      console.log("this.inviteCode1", this.inviteCode)
      this.copy('https://www.hk1686.com/x#/pages/buy/productDetail/productDetail?goodsSn=' + this.goods_sn + "&recommend_code=" + this.inviteCode)
      this.setCookie("inviteCode", this.inviteCode)
    }

    /* 直接訪問cookic*/
    /* if (!this.getCookie()) {
       this.eventMsgTost("请先登录")
       this.$router.push('/login')
     }else{
       let inviteCode=this.getCookie('inviteCode')
       inviteCode=inviteCode.substring(inviteCode.length-6)
       console.log("fadfad",inviteCode)
       this.copy(window.location.href + "?inviteCode=" + inviteCode)
     }*/

    /*  // console.log(window.location.href, 11111)
      this.copy(window.location.href)*/
  }

  //返回商品类型对应的包赔类型图片
  @Emit()
  getTypeGuaranteeImg(accountTypeName: string) {
    switch (accountTypeName) {
      case "官方代售":
        return require('static/image/goods/kmbp.png')
      case "官方自营":
        return require('static/image/goods/gfzy.png')
      default:
        return ""
    }
  }

  //返回商品类型对应的包赔类型图片
  @Emit()
  getTypeGuaranteeImg1(ensureOpen: any) {
    if (ensureOpen == 1) {
      return "可买包赔"
    } else {
      return ""
    }
    //   if (!!accountTypeName&&accountTypeName.indexOf(",")>-1){
    //     if (accountTypeName.split(",")[0]=="找回包赔"){
    //        return "可买包赔"
    //     }
    //     return accountTypeName.split(",")[0]
    //   }
    //   return accountTypeName
  }
  //打开在线客服
  openOnService() {
    window.open("https://www.hk1686.com/kfyz")
  }
  // 打开售前在线客服
  openPreSaleSerLink() {
    if (Object.keys(this.preSaleSerObj_).length > 0) {
      if (this.preSaleSerObj_.htUrl) {
        this.openOnlineService(this.preSaleSerObj_.htUrl, this.goodsInfo_.goodsSn, this.isMyGoods(this.goodsInfo_.pullId) ? '卖家' : '买家')
      } else if (this.preSaleSerObj_.serQqNum) {
        window.open(`http://wpa.qq.com/msgrd?v=3&uin=${this.preSaleSerObj_.serQqNum}&site=qq&menu=yes`, '_blank')
      } else {
        if (this.$store.state.web.distributorsObject && this.$store.state.web.distributorsObject.customerBlock.onlineSrvLink) {
          // window.open(this.$store.state.web.distributorsObject.customerBlock.onlineSrvLink, '_blank')
          this.openOnlineService(this.$store.state.web.distributorsObject.customerBlock.onlineSrvLink, this.goodsInfo_.goodsSn, this.isMyGoods(this.goodsInfo_.pullId) ? '卖家' : '买家')
        }
      }
    } else {
      if (this.$store.state.web.distributorsObject && this.$store.state.web.distributorsObject.customerBlock.onlineSrvLink) {
        // window.open(this.$store.state.web.distributorsObject.customerBlock.onlineSrvLink, '_blank')
        this.openOnlineService(this.$store.state.web.distributorsObject.customerBlock.onlineSrvLink, this.goodsInfo_.goodsSn, this.isMyGoods(this.goodsInfo_.pullId) ? '卖家' : '买家')
      } else if (this.$store.state.web.distributorsObject && this.$store.state.web.distributorsObject.customerBlock.serviceNum) {
        window.open(`http://wpa.qq.com/msgrd?v=3&uin=${this.$store.state.web.distributorsObject.customerBlock.serviceNum}&site=qq&menu=yes`, '_blank')
      }
    }
  }

  // 打开砍价弹窗
  seekBargain() {
    if (!this.getCookie()) {
      this.eventMsgTost("请先登录，以享受更全面的服务体验", "warning",)
      this.openUrl('/login')
      return
    }
    this.bargainShow = true
  }

  // 关闭砍价弹窗
  closeBargain(msg: any) {
    console.log(msg, '======msg===哈哈哈===')
    this.bargainShow = msg.flag
  }

  onOverDetail(index: any) {
    this.showDetailItemIndex = index
  }

  onLeaveDetail() {
    this.showDetailItemIndex = -1
  }

  get isShowDetailItemDialog() {
    return (index: any) => {
      if (this.showDetailItemIndex == index) {
        return true
      } else {
        return false
      }
    }
  }

  openThunbnailPreView() {
    if (this.goodsInfo_.goodsTypeId == 2 || this.goodsInfo_.goodsTypeId == 22) {
      return
    }
    this.showBox = true
    console.log('aergregaergag', this.thumbnailArea);
    this.thumbnailArea = []
    let newUrl: string = 'https://oss1.hk1686.com/'
    // if (window && window.location.host && this.goodsInfo_.distId==this.$store.state.web.distributorsObject.distId) {
    //   newUrl=`http://img.${window.location.host}.dianjinghou.cn/`
    // }
    if (this.goodsInfo_.distId == this.$store.state.web.distributorsObject.distId && this.distributorsObject && this.distributorsObject.distBaseConf.watermark) {
      if (this.goodsInfo_.thumbnail.indexOf('nocs-big') == -1 && this.goodsInfo_.gameId !== 149) {
        this.thumbnailArea.push(`${newUrl}${this.goodsInfo_.thumbnail}${this.distributorsObject.distBaseConf.watermark}`)
      } else {
        this.thumbnailArea.push(`${newUrl}${this.goodsInfo_.thumbnail}`)
        console.log(this.thumbnailArea.push(`${newUrl}${this.goodsInfo_.thumbnail}`))
      }
    } else {
      this.thumbnailArea.push(`${newUrl}${this.goodsInfo_.thumbnail}`)
    }
    this.$nextTick(() => {
      (this.$refs.dlg as any).show(0)
    })
  }

  isShowService() {
    this.isService = !this.isService
  }

  getheight() {
    let obj: any = this.$refs.goodsTitle
    let h = window.getComputedStyle(obj).height;  //通过这个方法，获取宽高
    // console.log("dhsadjahkjasdhk",h);
    this.goodsTitleHeight = parseInt(h.substring(0, h.length - 2))
  }

  modifyGoodsTitleIsShow() {
    this.goodsTitleIsShow = !this.goodsTitleIsShow;
    // this.$forceUpdate();
    console.log("dgsahdjkas", this.goodsTitleIsShow);

  }

  @Emit('goGoodsInfoPlace')
  goGoodsInfoPlace() {

  }


  @Emit()
  CloseMask() {
    this.kan_mask = false
  }

  @Emit()
  async kan_deposit() {

    if (this.kanjinfor.BuyId == this.yeuserId && this.kanjinfor.Status == 1) {
        this.checkDialogVisible = true;
      return
    }

   // if (this.goodsInfo_.cheaperStatus == 2 && this.goodsInfo_.cheaperSn && this.kanjinfor.BuyId == 0 ? true : false) return
    //商品价格小于80元，不支持砍价
    if (this.goodsInfo_.price < 100) return this.$message.warning('商品价格小于100元，不支持砍价');


    // if(this.kanjinfor.Status == 1) return this.$message.warning('该商品正在砍价中，请12小时候再查看');
    if (this.yeuserId) {
        let res = await Bargainingdeposit({ params: { goodsId: this.goodsInfo_.id } });
        if (res?.data.code == 0) {
          this.kan_mask = true
          this.Bargain = res?.data.data
        } else {
          return this.$message.warning(res?.data.msg);
        }


    } else {
      this.eventMsgTost("请先登录，以享受更全面的服务体验", "warning",)
      this.openUrl('/login')
    }

  }


  getgaimeId(item:any){
   

    if(item.gameId === 17 || item.gameId === 14 || item.gameId === 37){
     
      return true
    }

    return false
    
  }




  @Emit()
  async kan_success() { //砍价
    //检查是是否输入价格且是不是整数
    if (this.kan_input == "") {
      this.$message.warning('请输入价格');
      return
    }
    let reg = /^\d+$/
    if (!reg.test(this.kan_input)) {
      this.$message.warning('输入的价格只能是正整数');
      return
    }
    

    if (parseInt(this.kan_input) >= Math.round(this.goodsInfo_.price * 0.7) && parseInt(this.kan_input) <= this.goodsInfo_.price) {
      console.log("go")
    } else {
      this.$message.warning('报价不能低于最低报价或高于商品原价');
      return
    }
    if (!this.kan_checked) {
      this.$message.error('请同意 定金注意事项和购买须知');
      return
    }

    this.confirmDialogVisible = true;

  }



}
